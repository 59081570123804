import request from 'oc-request';
export default new class ConsultaForm{

    constructor() {
        this.eventListeners();
    }

    eventListeners(){
        const _this = this;
        document.addEventListener('readystatechange', (e) => {
            if(e.target.readyState === "complete") {
                let form = document.querySelector(`form[name='consulta-form']`);
                if (form)
                    form.addEventListener('submit', function(e) {
                        e.preventDefault();
                        e.stopPropagation();
                        form.classList.add('was-validated');
                        if(form.checkValidity() === false){
                            _this.showErrors(form);
                            _this.alertHandler('#errors');
                        }else{
                            _this.loaderHandler(form.parentElement.parentElement, '.loader-container');
                            request.sendForm(form, 'presupuestoForm::onFormSubmit', {
                                flash: true,
                                success: () => {
                                    _this.alertHandler('#success');
                                    _this.loaderHandler(form.parentElement.parentElement, '.loader-container');
                                    _this.resetForm(form);
                                }
                            })
                        }

                    })
            }
        })
    }

    showErrors(form) {
        let errorMessages = form.querySelectorAll('small.text-danger');
        errorMessages.forEach((el) => {
            el.classList.remove('d-none');
        })
    }

    hideErrors(form) {
        let errorMessages = form.querySelectorAll('small.text-danger');
        errorMessages.forEach((el) => {
            el.classList.add('d-none');
        })
    }

    alertHandler(selector) {
        setTimeout(() => {
            let alert = document.querySelector(`${selector}`);
            alert.classList.add('show');
            setTimeout(() => {
                alert.classList.remove('show');
            }, 5000)
        }, 300)
    }

    loaderHandler(form, selector) {
        let loader = form.querySelector(`${selector}`);
        if(loader.classList.contains('d-none')){
            loader.classList.remove('d-none');
        }else{
            loader.classList.add('d-none');
        }
    }

    resetForm(form) {
        form.classList.remove('was-validated');
        let fields = form.querySelectorAll('input[name], textarea[name]');

        fields.forEach((el) => {
            el.value = '';
        })
    }

}();
